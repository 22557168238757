@import 'styles/varibles.scss';
@import 'utils/styles/styles.scss';

$course-client-item-border: 1px solid $whisper;

.course-clients {
  display: flex;
  flex-direction: column;

  align-items: stretch;

  background-color: white;
  border-radius: 4px;
  box-shadow: $box-shadow;

  &__header {
    padding: 15px 24px;

    border-bottom: 1px solid $whisper;

    margin: 0;

    font-size: 22.3px;
    font-weight: 500;
  }

  &-list {
    //display: grid;
    //grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));

    &__item {
      padding: 15px 24px;


      &:hover {
        cursor: pointer;

        background-color: $whisper;

        transition: background-color 0.2s;
      }
    }

    &-item {
      display: flex;
      align-items: center;

      &__name {
        margin: 0 0 0 15px;
      }
    }
  }
  @media screen and (max-width: $mobile-size) {
    &__header {
      font-size: 16px;
    }
  }
}
