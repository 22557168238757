@import 'utils/styles/styles.scss';

.client-journey {
  &__content {
    padding: 0 55px;
  }
  @media screen and (max-width: $mobile-size) {
    &__content {
      padding: 16px 16px;
    }
  }
}
