@import 'styles/varibles.scss';

.side {
  display: flex;
  flex-direction: column;

  .text_bold {
    font-family: Avenir;
    font-size: 22px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.19px;
    color: $black-87;
  }
}
.side_left {
  float: left;
}
