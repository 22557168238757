@import '~utils/styles/styles.scss';
.client-course-session-time-actions {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
}

html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.contribution-view-calendar {
  @media screen and (max-width: $tablet) {
    width: 90vw;
    margin: 0 auto;
  }
}
