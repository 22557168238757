.label {
  margin-bottom: 20px;
}

.input_options {
  margin-top: -20px !important;
}

.input_sessions {
  margin-bottom: 25px !important;
}
