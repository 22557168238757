$text-color-main: rgba(0, 0, 0, 0.87);

body {
  color: $text-color-main;
}

a {
  color: inherit;

  &:hover {
    color: inherit;
  }
}
