$header-height: 95px;

// Colors
$white: #fff;
$black: #000;
$black-87: rgba(0, 0, 0, 0.87);
$whisper: #e7e7e7;
$snow: #fafafa;

// Shadows
$box-shadow: 0 2px 4px 0 rgba(157, 157, 157, 0.3);
