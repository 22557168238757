.tables-container {
  > div:first-child {
    margin-bottom: 30px;
  }

  .table__title {
    font-family: 'Nexa';
    color: rgba(0, 0, 0, 0.87);
    font-size: 17.8px;
    font-weight: 500;
    margin: 0 0 15px 0;
  }
}
