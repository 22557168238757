.video-chat-notes-panel {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__title {
    align-self: start;
    margin: 5px;

    font-weight: bold;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin: 0 23px;

    & button {
      margin-left: 10px;
    }
  }
}
