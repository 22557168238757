.profile-avatar {
  max-width: 155px;
  min-width: 155px;
  height: 155px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .profile-input-avatar {
    opacity: 0;
  }
}

.profile-avatar-text {
  margin-bottom: 0.5rem;
  margin-right: 1.5rem;
  text-align: center;

  transform: translate(0, 4px);
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-family: Avenir;
  font-weight: 300;
  line-height: 1rem;
  letter-spacing: 0.4px;
}
