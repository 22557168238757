@import 'styles/varibles';

.grid {
  flex-grow: 1;

  .grid_vertical {
    flex-grow: 1;
    align-content: baseline;

    .column-title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .column-title-container__text {
        font-family: Avenir;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.15px;
        color: $black-87;
      }
      .column-title-container__view-all {
        // border-bottom: 1px solid $black-87;
        color: $black-87;
        font-family: Avenir;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-decoration: underline;
      }
    }

    &_item {
      padding: 8px 0;
    }
  }
}
