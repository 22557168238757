@import-normalize;
// @import url("https://fonts.googleapis.com/css?family=Libre+Franklin:300,400,500,800&display=swap");
@import './styles/fonts.scss';
@import './styles/basic.scss';
@import './styles/reboot';

body {
  margin: 0;
  font-family: 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.no-scroll {
    overflow: hidden !important;
  }
}

.smoked-gary-color {
  color: #a9a9a9;
}

.hoverable {
  &:hover {
    cursor: pointer;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
