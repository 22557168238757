@import '~utils/styles/styles.scss';

.one-to-one-session {
  &-summary {
    display: flex;
    align-items: baseline;

    &__header {
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 1.33;
      letter-spacing: 0.12px;
      color: #000000;
      margin: 0;
      @media screen and (max-width: $mobile-size) {
        font-size: 14px;
      }
    }

    &__number-badge {
      background-color: $dark-ocean-blue-color;
      color: white;
      padding: 4px 12px;
      font-size: 0.6875rem;
      font-weight: 900;
      line-height: 1.45;
      letter-spacing: normal;
      text-align: center;
      border-radius: 10px;
      vertical-align: middle;
    }

    &__title,
    &__status {
      margin: 0 0 0 10px;
      @media screen and (max-width: $mobile-size) {
        font-size: 12px;
      }
    }

    &__status {
      font-style: italic;
      font-size: 13.7px;
      color: darkgray;
      @media screen and (max-width: $mobile-size) {
        font-size: 12px;
      }
    }
  }

  &-details {
    padding-left: 34px;
    width: 100%;
    @media screen and (max-width: $tablet) {
      padding-left: 0px;
    }

    &__header {
      margin: 0 0 15px 0;
      @media screen and (max-width: $mobile-size) {
        font-size: 16px;
      }
    }

    &-session-times {
      &__item {
        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }
    }
  }
  .MuiExpansionPanelDetails-root {
    @media screen and (max-width: $tablet) {
      width: 96vw;
    }
  }
}
