@import 'utils/styles/styles.scss';
.discount {
  min-height: 60.38px;
  margin-top: -20px !important;

  @media screen and (max-width: $mobile-size) {
    & {
      min-height: 0;
      margin-top: 0 !important;
    }
  }
}
