@import 'styles/varibles.scss';

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 150px;
  border-radius: 4px;
  background-color: $white;

  .card__action {
    margin: -13px -12px 0 0;
  }

  .card__title-name {
    font-family: Avenir;
    font-size: 17.8px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.15px;
    color: $black-87;
  }

  .card__title-link {
    &:hover {
      text-decoration: none;
    }
  }

  .card__content {
    padding: 16px;
    display: flex;
    justify-content: space-between;
  }

  .card__content:last-child {
    padding-bottom: 16px;
  }

  .card__title-session {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: $black;
  }

  .card__header-sales {
    padding: 18px 16px 18px 25px;
  }
  .card__action-sales {
    margin: -15px -12px 0 0;
  }
  .card__content-sales {
    padding: 16px 16px 16px 25px;
  }
}
