@import '~utils/styles/styles.scss';

.chats-list-container {
  display: grid;
  align-items: start;
  justify-items: center;

  width: 100%;
  height: 100%;
}

.chats-list {
  align-self: stretch;
  justify-self: stretch;

  display: flex;
  flex-direction: column;

  height: 100%;
  min-height: 400px;

  & &__header {
    cursor: default !important;

    border-bottom: 1px solid $light-gray-color;

    .MuiExpansionPanelSummary-content {
      flex-direction: column;
    }

    @media screen and (max-width: $laptop) {
      padding: 0 16px;
    }
  }

  .MuiCollapse-container.MuiCollapse-entered,
  .MuiCollapse-wrapper,
  .MuiCollapse-wrapperInner,
  .MuiCollapse-wrapperInner > div[role='region'] {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  & &__content {
    flex-grow: 1;

    padding: 0;
  }

  &-content {
    position: relative;

    &__channels-list {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
