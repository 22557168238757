.chat-message-input {
  display: grid;
  grid-template-columns: 1fr repeat(2, max-content);
  align-items: center;
  grid-column-gap: 20px;

  padding: 16px 24px;

  & &__upload-attachment,
  & &__send {
    margin: -12px;
  }

  & &__send {
    outline: none;
  }
}
