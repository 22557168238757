@import 'styles/varibles.scss';

.note-view {
  margin: 20px 23px 53px;
  max-width: 468px;
}

.note-view-mobile {
  height: 56vh;
  box-sizing: border-box;
}

.note-view__title {
  font-family: Avenir;
  font-size: 13.7px;
  line-height: 1.17;
  letter-spacing: 0.4px;
  color: $black-87;
  margin: 5px;
}
