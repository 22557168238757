.time-picker {
  display: flex;
  height: 60px;
  align-items: flex-end;
  width: 100%;
  padding: 0;
  .rc-time-picker-input {
    border: 0;
    border-bottom: 1px solid #e7e7e7;
    border-radius: 0;
    color: #000;
    font-size: 18px;
    font-weight: 400;
    outline: none;
  }
}
.rc-time-picker-panel-select {
  border: 0;
}
.time-picker__pop-up {
  display: flex;
  height: 200px;
  position: relative;
  .rc-time-picker-panel-input-wrap {
    font-size: 16px;
  }
  .rc-time-picker-panel-select {
    li {
      font-size: 14px;
      outline: none;
    }
  }
}
