@import 'utils/styles/styles.scss';

.card-container {
  width: 100%;

  .card-body-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__text {
      display: inline-block;
      line-height: 22px;
      margin-bottom: 0;

      &-amount {
        text-transform: uppercase;
      }
    }
  }
}
@media screen and (max-width: $mobile-size) {
  .card-body-container__mobile {
    flex-direction: column;

    .card-body-container__text {
      margin-bottom: 8px;
      margin-right: 0;
    }
  }
}
