@import '~utils/styles/styles.scss';

.one-to-one-session-time {
  p {
    margin: 0;
  }
  & > div {
    margin-right: 10px;
    width: 100px;
    @media screen and (max-width: $tablet) {
      width: 65px;
    }
    &:last-of-type {
      margin-left: auto;
      width: 200px;
    }
  }

  display: flex;
  align-items: baseline;
  justify-items: start;
  flex-wrap: wrap;

  &__number {
    justify-self: end;
  }

  &__label {
    grid-row: 1;

    font-weight: bold;
  }

  &__value {
    grid-row: 2;
  }

  &__label,
  &__value {
    &--type {
      &--date {
        grid-column: 1;
      }

      &--start-time {
        grid-column: 2;
      }

      &--end-time {
        grid-column: 3;
      }

      &--duration {
        grid-column: 4;
      }

      &--booked {
        grid-column: 5;
      }
    }
  }

  &__actions {
    grid-row: span 4;
    grid-column: 6;
    align-self: center;
    justify-self: end;

    display: grid;
    grid-template-columns: auto auto;
    button:nth-child(even) {
      margin-left: 10px;
    }
  }
}
