@import '~utils/styles/styles.scss';

.chat-channels-list {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: $light-gray-color-3;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: $gray-color-2;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $dark-gray-color;
  }

  &__item {
    padding: 14px 12px;

    border-bottom: 1px solid $light-gray-color-2;

    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: $mobile-size) {
      padding: 16px;
    }
  }

  & &__load-more-channels-progress {
    width: 30px !important;
    height: 30px !important;

    align-self: center;

    margin: 10px;
  }
}
